.about-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    color: #ffffff;
    background-color: #1a1a1a;
    border-radius: 15px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.about-container.loaded {
    opacity: 1;
    transform: scale(1);
}

.about-logo {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.logo-image {
    width: 400px;
    height: auto;
    animation: logo-fade-in 1s ease forwards;
}

@keyframes logo-fade-in {
    from { opacity: 0; transform: scale(0.8); }
    to { opacity: 1; transform: scale(1); }
}

.about-title {
    font-size: 2rem;
    color: #ffcc00;
    margin: 10px 0 20px;
}

.about-description {
    font-size: 1.2rem;
    margin-bottom: 30px;
}

.about-rules-title {
    font-size: 1.6rem;
    color: #ffcc00;
    margin: 30px 0 15px;
}

.about-rules {
    list-style-type: none;
    padding: 0;
    margin: 0;
    text-align: left;
}

.about-rules li {
    font-size: 1rem;
    margin: 10px 0;
    padding-left: 10px;
    color: #dddddd;
    position: relative;
}

.about-note {
    font-size: 1rem;
    color: #dddddd;
    margin-top: 30px;
}

/* Мобильная адаптация */
@media (max-width: 768px) {
    .about-container {
        max-width: 100%; /* Полная ширина на мобильных устройствах */
        padding: 15px; /* Уменьшенные отступы */
    }

    .about-title {
        font-size: 1.5rem; /* Уменьшенный размер шрифта */
    }

    .about-description, .about-note {
        font-size: 1rem; /* Уменьшение размера шрифта */
    }

    .about-rules-title {
        font-size: 1.4rem; /* Уменьшение размера шрифта */
    }

    .about-rules li {
        font-size: 0.9rem; /* Уменьшение размера шрифта в списке правил */
    }

    .logo-image {
        width: 260px; /* Уменьшение размера логотипа */
    }
}