.custom-icon {
    filter: invert(1); /* Инвертирует цвета, чтобы иконка была белой */
}

.listeners-map-container {
    width: 100%;
    max-width: 1030px;
    height: 400px;
    margin: 0 auto;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
}