/* Основные стили навбара */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 50px; /* Отступы от краёв для интересного размещения */
    /* background-color: #282828; */
    color: #ffffff;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); */
    position: relative;
    font-size: 1.2rem;
    z-index: 1000;
}

.navbar-logo {
    font-weight: bold;
    font-size: 1.5rem;
    margin-right: auto;
    color: #ffcc00;
}

.navbar-links {
    list-style-type: none;
    display: flex;
    gap: 30px; /* Большее расстояние между ссылками */
}

.navbar-links li a {
    text-decoration: none;
    color: #ffffff;
    transition: color 0.3s ease;
}

.navbar-links li a:hover {
    color: #ffcc00;
}

/* Стили для бургер-меню */
.burger-icon {
    display: none; /* Скрываем на десктопной версии */
    font-size: 1.8rem;
    color: #ffcc00;
    cursor: pointer;
}

@media (max-width: 768px) {
    .navbar {
        padding: 15px 20px; /* Сокращаем отступы на мобильных устройствах */
    }

    .navbar-links {
        position: absolute;
        top: 60px;
        right: 0;
        background-color: #282828;
        flex-direction: column;
        gap: 20px;
        padding: 20px;
        width: 200px;
        box-shadow: -2px 4px 8px rgba(0, 0, 0, 0.5);
        border-radius: 10px 0 0 10px;
        
        /* Изначально скрыто с transform для анимации */
        transform: translateY(100%);
        opacity: 0;
        transition: transform 0.3s ease, opacity 0.3s ease;
        z-index: 1000;
    }

    /* Анимация появления меню снизу */
    .navbar-links.open {
        display: flex;
        transform: translateY(0); /* Плавное перемещение вверх */
        opacity: 1;
    }

    .burger-icon {
        display: block; /* Показываем на мобильных */
    }

    .navbar-logo {
        font-size: 1.7rem;
    }
    .burger-icon {
        transition: transform 0.2s;
    }
    
    .burger-icon:active {
        transform: scale(0.9); /* Небольшое уменьшение при нажатии */
    }
    .navbar-links li {
    opacity: 0;
    transform: translateY(-20px);
    transition: transform 0.3s ease, opacity 0.3s ease;
}

.navbar-links.open li {
    opacity: 1;
    transform: translateY(0);
}

.navbar-links.open li:nth-child(1) { transition-delay: 0.1s; }
.navbar-links.open li:nth-child(2) { transition-delay: 0.2s; }
.navbar-links.open li:nth-child(3) { transition-delay: 0.3s; }
    
}