.contact-container {
    width: 800px;
    height: 658px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    color: #ffffff;
    background-color: #1a1a1a;
    border-radius: 15px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 0.5s ease, transform 0.5s ease;
}
.custom-icon {
    filter: invert(1); /* Инвертирует цвета */
}

.contact-container.loaded {
    opacity: 1;
    transform: scale(1);
}

.contact-title {
    font-size: 2rem;
    color: #ffcc00;
    margin-bottom: 20px;
}

.map-container {
    width: 100%;
    height: 400px;
    margin-bottom: 30px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
}

.contact-subtitle {
    font-size: 1.6rem;
    color: #ffcc00;
    margin: 20px 0;
}

.contact-links {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.contact-links li a {
    text-decoration: none;
    color: #ffffff;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    gap: 10px;
    transition: color 0.3s ease;
}

.contact-links li a:hover {
    color: #ffcc00;
}

.telegram-icon {
    font-size: 1.5rem;
    color: #0088cc;
}

/* Мобильная адаптация */
@media (max-width: 768px) {
    .contact-container {
        width: auto; /* Полная ширина экрана */
        height: auto; /* Автоматическая высота */
        padding: 15px;
    }

    .contact-title {
        font-size: 1.5rem; /* Уменьшение размера заголовка */
    }

    .map-container {
        height: 300px; /* Уменьшение высоты карты */
    }

    .contact-subtitle {
        font-size: 1.4rem; /* Уменьшение размера подзаголовка */
    }

    .contact-links li a {
        font-size: 1rem; /* Уменьшение размера текста ссылок */
    }

    .telegram-icon {
        font-size: 1.3rem; /* Уменьшение размера иконки */
    }
}