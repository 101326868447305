

.player-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100vh; Высота 100% экрана */
    /* background-color: #101010; */
    color: #ffffff;
    overflow: hidden; /* Скрываем прокрутку */
    z-index: 800; /* Понижаем z-index, чтобы не перекрывал бургер-меню */
}

.player-content {
    display: flex;
    width: 1000px;
    max-width: 1100px; /* Максимальная ширина для больших экранов */
    background: linear-gradient(135deg, #282828, #121212);
    border-radius: 15px;
    overflow: hidden;
    /* box-shadow: 0 8px 30px rgba(0, 0, 0, 0.5); */
    padding: 20px;
}

.left-panel, .right-panel {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.vinyl-record {
    position: relative;
    width: 300px; /* Размер пластинки */
    height: 300px;
    animation: spin 8s linear infinite; /* Восстановлено вращение пластинки */
}

.vinyl-image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.4);
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.station-name {
    font-size: 2.2rem;
    color: #ffcc00;
    margin-top: 20px;
    text-align: center;
    letter-spacing: 1px;
}

.listeners {
    margin-top: 10px;
    font-size: 1.2rem;
    color: #ffdd57;
    display: flex;
    align-items: center;
}

.listeners-icon {
    font-size: 1.5rem;
    margin-right: 10px;
    color: #ffdd57;
}

.right-panel {
    width: 100%; /* Занимаем 100% ширины */
    background-color: #181818;
    border-radius: 15px;
}

.current-track {
    font-size: 1.8rem;
    color: #ffffff;
    margin-bottom: 20px;
    text-align: center;
}

.track-name {
    color: #f5b800; /* Цвет названия трека */
    font-weight: bold;
}

.audio-controls {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.audio-controls .play-pause-btn {
    font-size: 70px; /* Размер кнопки Play */
    cursor: pointer;
    color: #ffdd57;
    margin-right: 20px;
}

.audio-controls .volume-control {
    display: flex;
    align-items: center;
}

/* Убираем ползунок громкости для мобильной версии */
.volume-icon {
    font-size: 50px;
    margin-right: 10px;
    color: #ffffff;
}

.volume-slider {
    width: 150px;
}

/* Мобильная адаптация */
@media (max-width: 768px) {
    .player-container {
        width: auto; /* Установим ширину на весь экран */
        height: auto; /* Убираем фиксированную высоту */
        padding: 0; /* Убираем отступы */
    }

    .player-content {
        flex-direction: column; /* Вертикальная компоновка */
        width: 100%; /* Ширина 100% */
        padding: 20px; /* Уменьшаем отступы */
    }

    .vinyl-record {
        width: 240px; /* Увеличиваем размер пластинки для мобильной версии */
        height: 240px;
    }

    .current-track {
        font-size: 1.0rem; /* Уменьшаем размер шрифта для мобильной версии */
        text-align: center; /* Центрируем текст */
        width: 100%; /* Полная ширина с отступами по бокам */
    }
        /* Увеличиваем размер кнопки Play для мобильной версии */
        .audio-controls .play-pause-btn {
            font-size: 115px; /* Увеличиваем размер кнопки Play для мобильной версии */
        }

    /* Скрываем ползунок громкости только на мобильной версии */
    .audio-controls .volume-control {
        display: none; /* Скрываем контроль громкости для мобильной версии */
    }
    .station-name {
        font-size: 1.9rem;
        color: #ffcc00;
        margin-top: 20px;
        text-align: center;
        letter-spacing: 1px;
    }
    
}