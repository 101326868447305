/* LoadingScreen.css */
.loading-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #282828;
    z-index: 2000;
}

.loading-text {
    color: #ffcc00;
    font-size: 2.5rem;
    font-weight: bold;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
}