body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  background-color: #1a1a1a;
  color: #ffffff;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
/* AdvertisingMarquee */
.advertising-marquee {
    z-index: 5; /* Установите значение ниже 30 */
}

/* Player */
.player-container {
    z-index: 5; /* Установите значение ниже 30 */
}